import { InvalidTokenError } from 'jwt-decode';
import * as Sentry from '@sentry/react';
import { decodeJwtPayload } from '_js/utils/api/jwt';
import { getBrand } from '_js/brands';
import { getGoogleUserInfo, getGoogleIdToken } from '_js/utils/3rdparty/googleAuth';
import {
  setAuthorizationToken,
  removeAuthorizationCookie,
  getAuthorizationToken,
  getUrlToken,
} from '_js/utils/Authorization';
import { API } from '_js/store/constants';

import {
  SIGN_LOGIN,
  SIGN_LOGIN_FAILED,
  SIGN_LOGIN_SUCCESS,
  SIGN_LOGIN_STOP,
  UPDATE_QR_DATA,
} from '_js/store/actions/api/auth/login';
import {
  LOGIN_SMS,
  LOGIN_SMS_SUCCESS,
  LOGIN_SMS_FAILED,
  LOGIN_SMS_CODE,
  LOGIN_SMS_CODE_SUCCESS,
  LOGIN_SMS_CODE_FAILED,
} from '_js/store/actions/api/auth/loginSms';
import { isCrmUser } from '_js/utils/crm';

export const EXCHANGE_TOKENS = 'API/SYSTEM/EXCHANGE_TOKENS';
export const EXCHANGE_TOKENS_SUCCESS = 'API/SYSTEM/EXCHANGE_TOKENS_SUCCESS';
export const EXCHANGE_TOKENS_FAILED = 'API/SYSTEM/EXCHANGE_TOKENS_FAILED';

export const AUTH_LOADING = 'AUTH_LOADING';
export const loadAuthentication = () => ({
  type: AUTH_LOADING,
});

export const AUTH_LOADING_FAILED = 'AUTH_LOADING_FAILED';
export const authLoadingFailed = (error) => ({
  type: AUTH_LOADING_FAILED,
  error: true,
  payload: {
    error,
  },
});

export const UNAUTHENTICATED = 'UNAUTHENTICATED';
export const unauthenticated = (error) => {
  const action = {
    type: UNAUTHENTICATED,
  };

  if (error) {
    action.error = true;
    action.payload = {
      error,
    };
  }

  return action;
};

export const LOGOUT = 'LOGOUT';
export const logout = () => {
  removeAuthorizationCookie();

  return {
    type: LOGOUT,
  };
};

export const AUTHENTICATING = 'AUTHENTICATING';
export const authenticate = () => ({
  type: AUTHENTICATING,
});

export const AUTHENTICATED = 'AUTHENTICATED';
export const authenticationSuccess = (GoogleUser) => ({
  type: AUTHENTICATED,
  payload: {
    idToken: getGoogleIdToken(GoogleUser),
    user: getGoogleUserInfo(GoogleUser),
  },
});

export const UNAUTHORIZED = 'UNAUTHORIZED';
export const unauthorized = () => ({
  type: UNAUTHORIZED,
});

export const AUTHORIZING = 'AUTHORIZING';
export const authorize = () => ({
  type: AUTHORIZING,
});

export const AUTHORIZED = 'AUTHORIZED';
export const authorizationSuccess = (authorizationToken) => {
  setAuthorizationToken(authorizationToken);

  return {
    type: AUTHORIZED,
    payload: {
      authorizationToken,
    },
  };
};

export const INIT_AUTH = 'INIT_AUTH';
const success = (type, payload) => ({
  type,
  payload,
});

const failure = (type, error) => ({
  type,
  message: 'Failed to exchage tokens.',
  error,
});
export const exchangeTokens = () => ({
  type: API,
  types: [EXCHANGE_TOKENS, EXCHANGE_TOKENS_SUCCESS, EXCHANGE_TOKENS_FAILED],
  payload: () => ({
    url: `/sign/exchange-tokens?brand=${getBrand()}`,
    success,
    failure,
    auth: true,
  }),
  axios: {
    withCredentials: true,
  },
});
export const initAuth = (dispatch, requiresSecureLogin) => {
  const urlAuthorizationToken = getUrlToken(window.location.search);
  const cookieAuthorizationToken = getAuthorizationToken();
  const authorizationToken = urlAuthorizationToken || cookieAuthorizationToken;

  try {
    if (requiresSecureLogin) {
      dispatch(exchangeTokens());
    }
    if (isCrmUser()) {
      return loadAuthentication();
    }
    if (decodeJwtPayload(authorizationToken)) {
      return authorizationSuccess(authorizationToken);
    }
    return unauthorized();
  } catch (error) {
    if (!(error instanceof InvalidTokenError)) {
      Sentry.captureException(error);
    }
    return unauthorized();
  }
};

export const AUTH_STATES = {
  INIT_AUTH,
  AUTH_LOADING,
  AUTH_LOADING_FAILED,
  LOGOUT,
  UNAUTHENTICATED,
  AUTHENTICATING,
  AUTHENTICATED,
  UNAUTHORIZED,
  AUTHORIZING,
  AUTHORIZED,
  SIGN_LOGIN,
  SIGN_LOGIN_SUCCESS,
  SIGN_LOGIN_FAILED,
  SIGN_LOGIN_STOP,
  UPDATE_QR_DATA,
  LOGIN_SMS,
  LOGIN_SMS_SUCCESS,
  LOGIN_SMS_FAILED,
  LOGIN_SMS_CODE,
  LOGIN_SMS_CODE_SUCCESS,
  LOGIN_SMS_CODE_FAILED,
};
