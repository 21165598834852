// internal
import { API } from '_js/store/constants';

export const UPDATE_APPLICANTS = 'API/SYSTEM/UPDATE_APPLICANTS';
export const UPDATE_APPLICANTS_SUCCESS = 'API/SYSTEM/UPDATE_APPLICANTS_SUCCESS';
export const UPDATE_APPLICANTS_FAILED = 'API/SYSTEM/UPDATE_APPLICANTS_FAILED';

const success = (type, payload) => ({
  type,
  payload,
});

const failure = (type, error) => ({
  type,
  message: 'Failed to update applicants.',
  error,
});

export const updateApplicants = (applicationId, { applicant, coApplicant }) => ({
  type: API,
  types: [UPDATE_APPLICANTS, UPDATE_APPLICANTS_SUCCESS, UPDATE_APPLICANTS_FAILED],
  method: 'post',
  payload: () => ({
    url: `/applications/${applicationId}/applicants/update`,
    params: {
      applicant,
      coApplicant: Object.keys(coApplicant || {}).length ? coApplicant : null,
    },
    success,
    failure,
    throwOnError: true,
    auth: true,
  }),
  axios: {
    withCredentials: true,
  },
});
