// internal
import { API } from '_js/store/constants';
import { urlETags } from 'redux-shapeshifter-middleware';
import { rootUrl } from '_js/constants/Endpoints';

export const LOGIN_SMS = 'API/SYSTEM/LOGIN_SMS';
export const LOGIN_SMS_SUCCESS = 'API/SYSTEM/LOGIN_SMS_SUCCESS';
export const LOGIN_SMS_FAILED = 'API/SYSTEM/LOGIN_SMS_FAILED';

export const LOGIN_SMS_CODE = 'API/SYSTEM/LOGIN_SMS_CODE';
export const LOGIN_SMS_CODE_SUCCESS = 'API/SYSTEM/LOGIN_SMS_CODE_SUCCESS';
export const LOGIN_SMS_CODE_FAILED = 'API/SYSTEM/LOGIN_SMS_CODE_FAILED';
export const LOGIN_SMS_CODE_ERROR = 'LOGIN_SMS_CODE_ERROR';

const success = (type, payload) => ({
  type,
  payload,
});

const failure = (type, error) => ({
  type,
  error,
});

export const smsLoginError = () => ({
  type: LOGIN_SMS_FAILED,
});

const authBaseUrl = {
  axios: {
    baseURL: `${rootUrl.origin}/auth/v1`,
    withCredentials: true,
  },
};
const clearEtags = () => {
  Object.keys(urlETags || {}).forEach((etag) => {
    delete urlETags[etag];
  });
};
// eslint-disable-next-line import/prefer-default-export
export const loginSms = (nationalId) => ({
  type: API,
  types: [LOGIN_SMS, LOGIN_SMS_SUCCESS, LOGIN_SMS_FAILED],
  method: 'post',
  payload: () => ({
    url: '/smsLogin',
    params: nationalId,
    success,
    failure,
  }),
  ...authBaseUrl,
});

export const loginSmsCode = (body) => {
  clearEtags();
  return {
    type: API,
    types: [LOGIN_SMS_CODE, LOGIN_SMS_CODE_SUCCESS, LOGIN_SMS_CODE_FAILED],
    method: 'post',
    payload: () => ({
      url: '/authenticateSmsCode',
      params: body,
      success,
      failure,
      throwOnError: true,
    }),
    ...authBaseUrl,
  };
};
