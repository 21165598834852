// internal
import { API } from '_js/store/constants';

export const UNDO_ACCEPT_BID = 'API/SYSTEM/UNDO_ACCEPT_BID';
export const UNDO_ACCEPT_BID_SUCCESS = 'API/SYSTEM/UNDO_ACCEPT_BID_SUCCESS';
export const UNDO_ACCEPT_BID_FAILED = 'API/SYSTEM/UNDO_ACCEPT_BID_FAILED';

const success = (type, payload) => ({
  type,
  payload,
});

const failure = (type, error) => ({
  type,
  message: 'Failed to undo accept on bid.',
  error,
});

export const undoAcceptBid = (applicationId, bidId) => ({
  method: 'post',
  type: API,
  types: [UNDO_ACCEPT_BID, UNDO_ACCEPT_BID_SUCCESS, UNDO_ACCEPT_BID_FAILED],
  payload: () => ({
    url: `/applications/${applicationId}/bidding/bids/${bidId}/undo-accept`,
    success,
    failure,
    auth: true,
  }),
  axios: {
    withCredentials: true,
  },
});
